<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Users</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="userRequest.status"
                        label="Active"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>First Name</label>
                    <v-text-field
                        v-model="userRequest.firstName" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>Last Name</label>
                    <v-text-field
                        v-model="userRequest.lastName" 
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>Profile</label>
                    <v-combobox
                        v-model="userRequest.profileSelected"
                        :items="listProfile"
                        item-text="name"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        dense
                        @change="checkProfile()"
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>E-Mail</label>
                    <v-text-field
                        v-model="userRequest.email"
                        :rules=[validations.required,validations.email]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>Phone</label>
                    <v-text-field
                        v-model="userRequest.phone" 
                        :rules=[validations.required]
                        v-mask="'+1 (###) ###-####'"
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>State</label>
                    <v-combobox
                        v-model="userRequest.stateSelected"
                        :items="listState"
                        item-text="codeDescription"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    v-if="id == 0"
                    cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>New Password</label>
                    <v-text-field
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="password"
                        autocomplete="new-password"
                        :rules=[validations.password]
                        hint="Password must be at least 8 characters, 1 uppercase and 1 lowercase letter"
                        outlined
                        single-line
                        loading
                        @click:append="showPassword = !showPassword"
                        dense
                    >
                        <template v-slot:progress>
                            <v-progress-linear
                                :value="progress"
                                :color="color"
                                absolute
                                height="7"
                                >
                            </v-progress-linear>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col 
                    v-if="id == 0"
                    cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>Confirm Password</label>
                    <v-text-field
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        v-model="confirmPassword"
                        autocomplete="new-password"
                        :rules=[validations.samePassword(password,confirmPassword)]
                        single-line
                        outlined
                        @click:append="showConfirmPassword = !showConfirmPassword"
                        dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>City</label>
                    <v-text-field
                        v-model="userRequest.city" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                
                <v-col cols="12"
                    lg="8"
                    md="12"
                    sm="12"
                >
                    <label>Address</label>
                    <v-text-field
                        v-model="userRequest.address" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    v-if="showListUsers"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Users</label>
                    <v-combobox
                        v-model="userRequest.listUserSelected"
                        :items="listUser"
                        item-text="name"
                        item-value="id"
                        multiple
                        chips
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        dense
                    >
                        <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggleUsers"
                            >
                                <v-list-item-action>
                                    <v-icon :color="listUser.length > 0 ? 'indigo darken-4' : ''">
                                        {{ iconUser }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col 
                    v-if="showSalesPersonConfig"
                    cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>Buget Proposal</label>
                    <v-text-field
                        v-model="userRequest.budgetProposal" 
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    v-if="showSalesPersonConfig"
                    cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                >
                    <label>Discount</label>
                    <v-text-field
                        v-model="userRequest.discount" 
                        suffix=" %"
                        v-formatMoney="percFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <br />

            <ActionButtons
                :request="userRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required, validEmail, validPassword } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            userRequest: {
                id: 0,
                profileSelected: null,
                idProfile: 0,
                firstName: "",
                lastName: "",
                email: "",
                currentPassword: "",
                password: "",
                phone: "",
                stateID: "",
                stateSelected: "",
                city: "",
                address: "",
                budgetProposal: "0.00",
                discount: "0.00",
                status: 1,
                listUserSelected: [],
            },

            listUser: [],
            listProfile: [],
            listState: [],

            loading: false,
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,

            showListUsers: false,
            showSalesPersonConfig: false,

            validations: {
                required: required,
                email: validEmail,
                password: validPassword,
                samePassword (password, confirmPassword) {
                    if (password !== confirmPassword) {
                        return "Passwords not matched!"
                    }
                    else
                        return true;
                }
            },

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {

            progress () {
                return Math.min(100, this.password.length * 13)
            },

            color () {
                return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
            },

            allUsers () {
                return this.userRequest.listUserSelected != null && 
                       this.userRequest.listUserSelected != undefined &&
                       this.userRequest.listUserSelected.length === this.listUser.length
            },

            someUsers () {
                return this.userRequest.listUserSelected != null && 
                       this.userRequest.listUserSelected != undefined &&
                       this.userRequest.listUserSelected.length > 0 && !this.allUsers
            },

            iconUser () {
                if (this.allUsers) return 'mdi-close-box'
                if (this.someUsers) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },

        methods: {
            checkProfile() {

                this.showListUsers = false;
                this.showSalesPersonConfig = false;

                if (this.userRequest != null &&
                    this.userRequest != undefined &&
                    this.userRequest.profileSelected != null && 
                    this.userRequest.profileSelected != undefined) {
                    
                    this.showListUsers = this.userRequest.profileSelected.viewSchedulerOtherUsers == 1;
                    this.showSalesPersonConfig = this.userRequest.profileSelected.budgetControl == 1;
                }                       
            },

            toggleUsers () {
                this.$nextTick(() => {
                    if (this.allUsers) {
                        this.userRequest.listUserSelected = []
                    } else {
                        this.userRequest.listUserSelected = this.listUser.slice()
                    }
                })
            },

            validEmailAndPassword() {

                var isValidEmail = this.userRequest.email !== "" && validEmail(this.userRequest.email);

                var isValidPass = true;

                if (this.password != "" || this.confirmPassword != "")
                {
                    isValidPass = validPassword(this.password) === true && this.password === this.confirmPassword;
                }

                var isValidFrm = isValidEmail === true && isValidPass === true;

                return isValidFrm;
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("userModule/GetById", this.id);

                    this.userRequest = response;
                    this.userRequest.profileSelected = response.profile;
                    this.userRequest.budgetProposal = response.budgetProposalFormatted;
                    this.userRequest.discount = response.discountFormatted;

                    this.checkProfile();
                }
                else {
                    this.userRequest.budgetProposal = "$ 0,00"
                    this.userRequest.discount = "0.00"
                    
                    this.userRequest.stateSelected = {
                        id: 13,
                        codeDescription: "FL - Florida"
                    }
                }
            },

            async getLists() {
                
                this.listUser = await this.$store.dispatch("userModule/List");
                this.listProfile = await this.$store.dispatch("profileModule/List");
                this.listState = await this.$store.dispatch("stateModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/admin/userList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false || this.validEmailAndPassword() === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    this.userRequest.id = this.id;
                    this.userRequest.password = this.password;
                    this.userRequest.confirmPassword = this.confirmPassword;
                    this.userRequest.status = 1;
                    this.userRequest.idProfile = this.userRequest.profileSelected.id;
                    this.userRequest.stateID = this.userRequest.stateSelected.id;

                    if (this.userRequest.status === null) {
                        this.userRequest.status = 0;
                    }

                    if (this.userRequest.listUserSelected != null && this.userRequest.listUserSelected != undefined) {
                        this.userRequest.listIDUsers = '';

                        this.userRequest.listUserSelected.forEach(item => {
                            this.userRequest.listIDUsers += `${item.id};`
                        });
                    }

                    const result = await this.$store.dispatch("userModule/CreateUpdate", this.userRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>
